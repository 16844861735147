var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-account-group")]),_vm._v(" Clientes "),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","to":{ name: 'personCreate' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1)],1)],1),_c('v-col',{attrs:{"lg":"12","md":"12"}},[_c('v-card',{staticClass:"text-center"},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.persons),expression:"!persons"}],attrs:{"type":"warning","outlined":""}},[_vm._v(" Nenhum cliente encontrado. ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.persons},scopedSlots:_vm._u([{key:"item.birthdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.birthdate)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                    name: 'personsEdit',
                    params: { id: item.id }
                  }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }